.projects-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    //display: flex;
    flex-direction: column;
    img {
      margin-top: 5rem;
      width: 200px;
      height: 100px;
    }
  }
  .divider {
    height: 60%;
    width: 1px;
    background-color: whitesmoke;
    margin: 0 3rem;

    @media (max-width: 700px) {
      display: none;
    }
  }
}

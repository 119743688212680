.project-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 20%;
  @media (max-width: 700px) {
    width: 40%;
  }

  &:hover {
    cursor: pointer;
  }
}

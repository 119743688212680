.services-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .services-row {
    display: flex;
    flex-direction: row;
  }

  // :nth-child(even) {
  //   display: flex;
  //   flex-direction: row-reverse;
  // }
}

.App {
  background-color: #38434a;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .navbar {
    display: flex;
    opacity: 0;
    position: sticky;
    top: 0;
    width: 100%;
    height: 5rem;

    &.show {
      display: flex;
      animation-name: example;
      animation-duration: 300ms;
      opacity: 1;
      background-color: rgba(98, 110, 118, 0.9);
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  @keyframes example {
    from {
      display: none;
      opacity: 0;
    }
    to {
      display: flex;
      opacity: 1;
    }
  }

  .main-section {
    display: flex;
    margin-top: -5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
  }
  .services-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
  }
  .projects-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
  }
  .contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
  }
}

.main-wrapper {
  width: 30%;
  @media (max-width: 700px) {
    width: 90%;
    display: flex;
    flex-direction: column;
    //justify-content: centers;
    // align-items: center;
  }
  // width: 80%;
  // display: flex;
  // flex-direction: column;
  //justify-content: centers;
  // align-items: center;

  .center-wrapper {
    border-bottom: 1px solid lightgray;
    padding: 0.5rem 2rem;

    .logo {
      width: 40%;
      @media (min-width: 500px) {
        width: 70%;
      }
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.contact-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .info-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    .info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 10rem;
      color: #fab102;

      a {
        color: #fab102;
        &:hover {
          color: white;
        }
      }
    }
  }
}

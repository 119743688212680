.nav-bar-wrapper {
  display: flex;
  flex-direction: row;
  .menu-btn {
    padding: 0.5rem 1rem;
    &:hover {
      cursor: pointer;
    }
    &.show {
      text-decoration: underline;
    }
  }
}

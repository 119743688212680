.button {
  margin: 1rem 1rem;
  padding: 0.3rem 1.5rem;
  color: #fab102;
  border: 1px solid #fab102;
  border-radius: 15px;

  &:hover {
    background-color: #eec665;
    color: white;
    cursor: pointer;
  }
}

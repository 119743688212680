.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3rem;
  width: 100%;
  justify-content: space-around;
  background-color: whitesmoke;
  border-bottom: 1px solid #fab102;
  margin: 1rem;
  .icon {
    color: #38434a;
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .about-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #38434a;
  }
}
